export function Schema() {
  return {
    category: 'Image',
    label: 'Fifty Fifty Image',
    key: 'fiftyFiftyImage',
    previewSrc:
      'https://cdn.shopify.com/s/files/1/1011/0318/files/Screen_20Shot_202023-05-23_20at_2010.45.53_20AM.png?v=1684864558',
    fields: [
      {
        label: 'Image Flipped',
        name: 'imageFlipped',
        component: 'toggle',
        toggleLabels: {
          true: 'On',
          false: 'Off',
        },
        defaultValue: false,
      },
      {
        label: 'Dark Mode',
        name: 'darkMode',
        description: 'Set The Background dark and text white',
        component: 'toggle',
        defaultValue: false,
      },
      {
        label: 'Image',
        name: 'image',
        component: 'image',
      },
      {
        label: 'Video URL',
        name: 'videoUrl',
        component: 'text',
        description: 'Replaces the image with a video if set',
      },
      {
        label: 'Image Eyebrow',
        name: 'imageEyebrow',
        component: 'text',
        defaultValue: 'EFFECTIVELY REMOVES',
      },
      {
        label: 'Image Heading',
        name: 'imageHeading',
        component: 'text',
        defaultValue: '99% of 220 contaminants',
      },
      {
        label: 'Eyebrow',
        name: 'eyebrow',
        component: 'text',
        defaultValue: 'Our Technology',
      },
      {
        label: 'Heading',
        name: 'heading',
        component: 'text',
        defaultValue: 'The most powerful filtration media on the planet.',
      },
      {
        label: 'Copy',
        name: 'copy',
        component: 'text',
        defaultValue:
          'Traditionally, water pitchers have not been serious filtration devices. The Clearly Filtered® Water Pitcher with Affinity® Filtration Technology changes everything, removing up to 99.9% of 365+ harmful and dangerous tap water contaminants. That’s more than the 3 leading brand name pitchers combined.',
      },
      {
        label: 'CTA Copy',
        name: 'ctaCopy',
        component: 'text',
        defaultValue: 'Our Technology',
      },
      {
        label: 'CTA Url',
        name: 'ctaUrl',
        component: 'text',
        defaultValue: '#',
      },
      {
        label: 'CTA Theme',
        name: 'ctaTheme',
        component: 'select',
        defaultValue: 'primary',
        options: [
          { label: 'Primary', value: 'primary' },
          { label: 'Secondary', value: 'secondary' },
          { label: 'Light', value: 'light' },
          { label: 'Dark', value: 'dark' },
        ],
      },
      {
        label: 'CTA Variant',
        name: 'ctaVariant',
        component: 'select',
        defaultValue: 'outlined',
        options: [
          { label: 'Outlined', value: 'outlined' },
          { label: 'Filled', value: 'filled' },
        ],
      },
    ],
  };
}
