export function useAuth() {
  const endpoint = `https://clearly-filtered.myshopify.com/api/${process.env.SHOPIFY_STOREFRONT_API_VERSION}/graphql.json`;

  const getCustomerAccessTokenWithMultipass = async (multipassToken) => {
    const query = `mutation customerAccessTokenCreateWithMultipass($multipassToken: String!) {
      customerAccessTokenCreateWithMultipass(multipassToken: $multipassToken) {
        customerAccessToken {
          accessToken
          expiresAt
        }
        customerUserErrors {
          code
          field
          message
        }
      }
    }`;
    const variables = {
      multipassToken,
    };

    // TODO: move fetch to a helper function
    try {
      const result = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Shopify-Storefront-Access-Token':
            process.env.SHOPIFY_STOREFRONT_API_TOKEN,
        },
        body: { query, variables } && JSON.stringify({ query, variables }),
      });

      const data = await result.json();

      if (!data?.data?.customerAccessTokenCreateWithMultipass) {
        throw new Error('No data returned from Shopify');
      }

      return {
        status: result.status,
        body: data.data.customerAccessTokenCreateWithMultipass,
      };
    } catch (error) {
      console.error('Error:', error);
      return {
        status: 500,
        error: 'Error receiving data',
      };
    }
  };

  const getCustomerByAccessToken = async (accessToken) => {
    const query = `{
      customer(customerAccessToken: "${accessToken}") {
        id
        firstName
        lastName
        acceptsMarketing
        email
        tags
      }
    }`;

    try {
      const result = await fetch(endpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Shopify-Storefront-Access-Token':
            process.env.SHOPIFY_STOREFRONT_API_TOKEN,
        },
        body: { query } && JSON.stringify({ query }),
      });

      const data = await result.json();

      if (!data?.data?.customer) {
        throw new Error('No data returned from Shopify');
      }

      return {
        status: result.status,
        body: data.data.customer,
      };
    } catch (error) {
      console.error('Error:', error);
      return {
        status: 500,
        error: 'Error receiving data',
      };
    }
  };

  return { getCustomerAccessTokenWithMultipass, getCustomerByAccessToken };
}
