import { useEffect } from 'react';
import { useGlobalContext } from '../../contexts';

export function TestVariant() {
  const {
    actions: { setTestVariant },
  } = useGlobalContext();

  useEffect(() => {
    if (typeof window === 'undefined') {
      return;
    }

    // Get test variant from local storage
    const localStorageRecord = localStorage.getItem('bear:testVariant') ?? '{}';
    const testVariantObj = JSON.parse(localStorageRecord);
    setTestVariant(testVariantObj);

    // Expose function to set test variant
    window.setTestVariant = (testName, variantName) => {
      if (!testName || !variantName) {
        console.error('Test name and variant name are required');
        return;
      }

      if (typeof testName !== 'string' || typeof variantName !== 'string') {
        console.error('Test name and variant name must be strings');
        return;
      }

      testVariantObj[testName] = variantName;
      // Set in local storage
      localStorage.setItem('bear:testVariant', JSON.stringify(testVariantObj));

      setTestVariant(testVariantObj);
    };
  }, []);
}
