import {
  useCartAddDiscountUrl,
  useSettings,
  useStorefrontInit,
} from '@backpackjs/storefront';

import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { GlobalContextProvider } from '../contexts';
import { EWGContext } from '../contexts/ewgContext';
import {
  useCjAffiliate,
  useScrollPosition,
  useSetViewportHeightCssVar,
  useShopifyAnalytics,
  useThemeColors,
} from '../hooks';
import { useEWGResults } from '../hooks/EWG/useEWGResults';
import {
  Account,
  Cart,
  DataLayer,
  Drawer,
  Footer,
  Header,
  Modal,
  Overlay,
  SchemaMarkup,
  Scripts,
  Search,
  StorefrontHead,
  TestVariant,
  Upsellit,
  VisuallyIo,
} from '../snippets';

function StorefrontLayout({ ContentForLayout, ...props }) {
  const settings = useSettings();
  const { asPath } = useRouter();
  const scrolledPast = useScrollPosition(100);
  const [transparentMode, setTransparentMode] = useState(false);
  const [transparentModeTextColor, setTransparentModeTextColor] =
    useState('dark');
  const [initialTransparentMode, setInitialTransparentMode] = useState(false);
  const { page } = props;
  const EWGProviderValue = useEWGResults(page);

  const promobar = settings?.header?.promobar;
  const promobarDisabled =
    !!promobar && (!promobar.enabled || !promobar.messages?.length);

  const [promobarHidden, setPromobarHidden] = useState(false);

  useEffect(() => {
    let found = false;
    settings?.header?.appearance.forEach((page) => {
      if (asPath.split('?').shift() === page?.affectedPage?.url) {
        setTransparentMode(page.transparentMode);
        setInitialTransparentMode(page.transparentMode);
        setTransparentModeTextColor(page.transparentModeTextColor);
        found = true;
      } else if (found === false) {
        setTransparentMode(false);
        setInitialTransparentMode(false);
        setTransparentModeTextColor('dark');
      }
    });
  }, [asPath, settings]);

  useEffect(() => {
    if (initialTransparentMode) {
      if (scrolledPast) {
        setTransparentMode(false);
      } else {
        setTransparentMode(true);
      }
    }
  }, [scrolledPast]);

  /* Load globally used scripts */
  useEffect(() => {
    // Load Okendo
    if (!document.getElementById('okendo-script')) {
      const metaTag = document.createElement('meta');
      metaTag.name = 'oke:subscriber_id';
      metaTag.content = '1ad203d1-4b76-42c0-b9db-d76307bcf033';
      document.head.appendChild(metaTag);

      const scriptTag = document.createElement('script');
      scriptTag.id = 'okendo-script';
      scriptTag.src =
        'https://cdn-static.okendo.io/reviews-widget-plus/js/okendo-reviews.js';
      document.head.appendChild(scriptTag);
    }
    // Load Gorgias
    if (!document.getElementById('gorgias-chat-widget-install-v3')) {
      const scriptTag = document.createElement('script');
      scriptTag.id = 'gorgias-chat-widget-install-v3';
      scriptTag.src =
        'https://config.gorgias.chat/bundle-loader/01GYCBTQX2BYJ377PC4Q3P5K4N';
      document.head.appendChild(scriptTag);
    }
    // Gorgias Convert Bundle Loader
    if (!document.getElementById('convert-bundle-loader')) {
      const scriptTag = document.createElement('script');
      scriptTag.id = 'convert-bundle-loader';
      scriptTag.src =
        'https://bundle.dyn-rev.app/loader.js?g_cvt_id=1d937050-2158-458a-9480-d20341ca9294';
      document.head.appendChild(scriptTag);
    }
  }, []);

  useCjAffiliate();

  // initialize customer, cart, shop and markets
  useStorefrontInit();

  // auto applies cart discount found on the landing url
  useCartAddDiscountUrl();

  // set css var for viewport height
  useSetViewportHeightCssVar();

  // set css vars for theme colors
  useThemeColors();

  // send page view and add to cart events to Shopify analytics
  useShopifyAnalytics({ collection: props.collection, product: props.product });

  return (
    <EWGContext.Provider value={EWGProviderValue}>
      <GlobalContextProvider>
        <StorefrontHead />

        <TestVariant />

        <VisuallyIo {...props} />

        <DataLayer {...props} />

        <Scripts />

        <SchemaMarkup {...props} />

        <Header
          page={page}
          scrolledPast={scrolledPast}
          setPromoBarHidden={setPromobarHidden}
          promobarHidden={promobarHidden}
          transparentModeTextColor={transparentModeTextColor}
          transparentMode={transparentMode}
          promobarDisabled={promobarDisabled}
        />
        <main
          className={`${
            promobarDisabled
              ? 'pt-[60px] md:pt-[var(--header-height)]'
              : 'pt-[calc(60px+var(--promobar-height))] md:pt-[calc(var(--header-height)+var(--promobar-height))] print:!pt-0'
          } ${initialTransparentMode ? '!pt-0' : ''} ${
            page?.template?.type === 'product' && scrolledPast ? '' : ''
          }`}
        >
          <ContentForLayout {...props} />
        </main>

        <Footer />

        <Overlay />

        <Cart />

        <Search />

        <Account promobarHidden={promobarHidden} />

        <Modal />

        <Drawer />

        <Upsellit />
      </GlobalContextProvider>
    </EWGContext.Provider>
  );
}

export default StorefrontLayout;
