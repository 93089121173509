import { useState } from 'react';
import { useSettings } from '@backpackjs/storefront';
import { useRouter } from 'next/router';

import { BeamSelectNonprofit } from '../Beam';
import { CartEmpty } from './CartEmpty';
import { CartHeader } from './CartHeader';
import { CartItem } from './CartItem';
import { CartTotals } from './CartTotals';
import { CartUpsell } from './CartUpsell';
import { CartDiscount } from './CartDiscount';
import { FreeShippingMeter } from './FreeShippingMeter';
import { useGlobalContext } from '../../contexts';
import { useCartItems } from '../../hooks';

export function Cart() {
  const cartItems = useCartItems();
  const settings = useSettings();
  const { asPath } = useRouter();
  const {
    state: { cartOpen },
    actions: { closeCart },
  } = useGlobalContext();
  const [isUpdating, setIsUpdating] = useState(false);

  const isCartPage = () => {
    return asPath === '/cart';
  };

  const enabledUpsell = settings?.cart?.upsell?.enabled;
  const beamSettings = settings?.beam;

  return (
    <aside
      className={`fixed left-full top-0 z-[70] flex h-full w-full flex-col justify-between overflow-hidden bg-[#fff] transition md:max-w-[460px] ${
        cartOpen
          ? 'pointer-events-auto -translate-x-full'
          : 'pointer-events-none translate-x-0'
      } ${isCartPage() ? 'hidden' : null}`}
    >
      {cartOpen && !isCartPage() && (
        <>
          <CartHeader
            closeCart={closeCart}
            heading={settings?.cart?.heading}
            announcement={settings?.cart?.announcement}
          />

          <FreeShippingMeter />

          <div className="cart__wrapper relative flex-1 overflow-y-auto scrollbar-hide">
            <ul>
              {cartItems?.length
                ? cartItems.map((item) => {
                    return (
                      <li key={item.id} className="">
                        <CartItem
                          item={item}
                          closeCart={closeCart}
                          setIsUpdating={setIsUpdating}
                        />
                      </li>
                    );
                  })
                : !isUpdating && <CartEmpty closeCart={closeCart} />}
            </ul>

            {!!beamSettings && !!cartItems?.length && (
              <div className="px-4 py-6">
                <BeamSelectNonprofit />
              </div>
            )}
          </div>

          {enabledUpsell && <CartUpsell closeCart={closeCart} />}

          {!!cartItems?.length && <CartDiscount />}

          <CartTotals />
        </>
      )}
    </aside>
  );
}

Cart.displayName = 'Cart';
