import { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { ImageElement } from './ImageElement';

// Intended for use with images hosted by a CDN only
// For local images (e.g. `import Image from './image.jpg'`) and remote images (e.g. `/image.jpg`), use the <img> html tag directly

const DOMAIN = 'cdn.shopify.com';

export const Image = forwardRef(
  (
    {
      alt, // required
      className,
      fill, // if true, parent element must be position 'relative', 'absolute', or 'fixed'
      height, // required if fill is false
      loading, // 'auto' | 'eager' | 'lazy'
      priority, // if true, image will be preloaded and have eager loading
      quality, // 1 - 100
      sizes,
      src, // required
      width, // required if fill is false
      ...props
    },
    ref
  ) => {
    if (process.env.NODE_ENV !== 'production') {
      // errors
      if (!src) {
        throw new Error(
          `Image is missing required "src" property. Make sure you pass "src" in props to the Image component. Received: ${src}.`
        );
      }
      if (
        (typeof src === 'string' && src.startsWith('/')) ||
        (typeof src === 'object' && src.src)
      ) {
        throw new Error(
          `Invalid prop "src" supplied to "Image". For local images (e.g. "import Image from './image.jpg'") and remote images (e.g. "'/image.jpg'"), use the "<img>" html tag directly.`
        );
      }
      if (typeof src !== 'string') {
        throw new Error(
          `Invalid prop "src" of type "${typeof src}" supplied to "Image", expected "string".`
        );
      }
      const url = src.toLowerCase();
      if (!url.startsWith(DOMAIN) && !url.split('//')[1]?.startsWith(DOMAIN)) {
        throw new Error(
          `Invalid prop "src" supplied to' "Image". Must be url from "${DOMAIN}".`
        );
      }
      if ((!fill && (!width || !height)) || (fill && (width || height))) {
        throw new Error(
          `Image with src "${src}" must either use: both "width" and "height" properties only; or "fill={true}" property only.`
        );
      }
      // warnings
      if (!alt) {
        console.warn(
          `Image is missing required "alt" property. Please add Alternative Text to describe the image for screen readers and search engines.`
        );
      }
      if (fill && !sizes) {
        console.warn(
          `Image with src "${src}" has "fill" but is missing "sizes" prop. Please add it to improve page performance.`
        );
      }
    }

    return (
      <ImageElement
        alt={alt}
        className={className}
        fill={fill}
        height={height}
        loading={loading}
        priority={priority}
        quality={quality}
        ref={ref}
        sizes={sizes}
        src={src}
        width={width}
        {...props}
      />
    );
  }
);

Image.displayName = 'Image';
Image.defaultProps = {
  alt: '',
  className: '',
  fill: false,
  height: undefined,
  loading: 'lazy',
  priority: false,
  quality: undefined,
  sizes: '',
  src: '',
  width: undefined,
};
Image.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  fill: PropTypes.bool,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  loading: PropTypes.oneOf(['auto', 'eager', 'lazy']),
  priority: PropTypes.bool,
  quality: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sizes: PropTypes.string,
  src: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
