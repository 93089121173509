import { useCallback } from 'react';
import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Button from '../../snippets/Button/Button';
import { Body, Eyebrow, Headline } from '../../snippets/Typography';
import { Image } from '../../snippets';
import Schema from './FiltrationTechnology.schema';
import { FiltrationTechnologyDrawer } from './FiltrationTechnologyDrawer';
import { FiltrationTechnologyVideoPreview } from './FiltrationTechnologyVideoPreview';

import { useGlobalContext } from '../../contexts';

export function FiltrationTechnology({ cms }) {
  const {
    backgroundImage,
    eyebrowText,
    heading,
    subheading,
    ctas,
    image,
    videoPreviewSettings,
    modalSettings,
    callouts,
    backgroundVideoUrl,
  } = cms;

  const {
    actions: { openDrawer },
  } = useGlobalContext();

  const openVideoModal = useCallback(() => {
    openDrawer(<FiltrationTechnologyDrawer content={modalSettings} />);
  }, []);

  return (
    <div className="bg-gradient relative bg-gradient-to-t from-[#050b1b] to-[#2e436c]">
      {backgroundVideoUrl && (
        <div className="absolute inset-0 z-[1]">
          <video
            src={backgroundVideoUrl}
            autoPlay
            loop
            muted
            playsInline
            className="h-full w-full object-cover"
          />
        </div>
      )}
      {!backgroundVideoUrl && backgroundImage && (
        <div className="bg-content absolute inset-0 z-[1] h-full w-full">
          <Image
            src={backgroundImage.src}
            alt={backgroundImage.alt}
            width={1440}
            height={870}
            className="hidden h-full w-full object-cover md:block"
          />
        </div>
      )}

      <div className="relative z-[2] py-20 pt-[8px] text-white">
        <div className="px-contained relative mx-auto flex max-w-[--var(content-max-width)] flex-col gap-10 pt-20">
          <div className="flex flex-col items-center text-center">
            <Eyebrow className="mb-3">{eyebrowText}</Eyebrow>
            <Headline variant="h3" className="mb-4 max-lg:text-[30px]">
              {heading}
            </Headline>
            <div className="max-w-3xl">
              <Body variant="medium" className="max-lg:text-[16px]">
                {subheading}
              </Body>
            </div>
          </div>
          {ctas && ctas.length && (
            <div className="flex justify-center gap-5">
              {ctas.map((cta, index) => {
                if ('link' in cta) {
                  return (
                    <Button
                      key={index}
                      href={cta.link.url}
                      variant={cta.variant}
                      theme={cta.theme}
                    >
                      {cta.link.text}
                    </Button>
                  );
                }
                return null;
              })}
            </div>
          )}

          {image && (
            <div className="mx-auto flex max-w-[386px] justify-center px-4">
              <img
                src={`${image.src}&width=400&quality=60`}
                alt={image.alt ?? 'Filtration Technology Image'}
                width={400}
                height={400}
                loading="lazy"
              />
            </div>
          )}

          {videoPreviewSettings && videoPreviewSettings.image && (
            <div
              className="px-4 md:absolute md:bottom-4"
              onClick={openVideoModal}
              aria-hidden="true"
            >
              <FiltrationTechnologyVideoPreview
                content={videoPreviewSettings}
              />
            </div>
          )}
        </div>
        <hr className="my-9 opacity-20" />
        <div className="px-contained relative mx-auto max-w-[var(--content-max-width)] text-center md:text-left">
          <Swiper
            modules={[Pagination]}
            slidesPerView={1}
            pagination={{
              clickable: true,
              el: '#slider-nav',
              type: 'bullets',
              renderBullet(_, className) {
                return `<span class="${className}" style="background-color: #FFFFFF"></span>`;
              },
            }}
            breakpoints={{
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            {callouts.map((callout, index) => {
              return (
                <SwiperSlide
                  className="[&:first-child>.callout]:border-l-0 "
                  key={index}
                >
                  <div className="callout border-l-[#FFFFFF33] px-[25px] md:px-[50px] lg:border-l">
                    <div className="callout__image mx-auto mb-6 inline-block lg:mx-0">
                      <Image
                        src={callout.image.src}
                        alt={callout.image.alt ?? 'Filtration Technology Icon'}
                        width={56}
                        height={56}
                        className="h-14 w-14 object-contain"
                      />
                    </div>
                    <div className="callout__text text-lg">
                      <p className="mb-2 text-[1.19rem]">{callout.heading}</p>
                      <Body variant="small">{callout.content}</Body>
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
          <div
            id="slider-nav"
            className="absolute left-0 -mb-10 flex w-full justify-center"
          />
        </div>
      </div>
    </div>
  );
}

FiltrationTechnology.displayName = 'FiltrationTechnology';
FiltrationTechnology.Schema = Schema;
