import { useEffect, useState } from 'react';
import { Schema } from './JazzHRListings.schema';
import { JobListing } from './JobListing';

export function JazzHRListings() {
  const [jobs, setJobs] = useState([]);

  const getJobs = async () => {
    const jazzResponse = await fetch('/api/jazz-hr-jobs');
    if (jazzResponse.status === 200) {
      const body = await jazzResponse.json();
      setJobs(Array.isArray(body) ? body : [body]);
    }
  };

  useEffect(() => {
    getJobs();
  }, []);

  return (
    <div className="bg-clearBlue">
      <div className="px-contained mx-auto max-w-3xl overflow-hidden pb-4 pt-16">
        <div>
          <h4 className="mb-8 text-4xl font-light tracking-[-.01em]">
            Open Listings
          </h4>
        </div>
        {jobs.map((job) => (
          <JobListing job={job} />
        ))}
      </div>
    </div>
  );
}

JazzHRListings.displayName = 'JazzHR Listings';
JazzHRListings.Schema = Schema;
