import { useEffect, useState } from 'react';
import { useBlog } from '@backpackjs/storefront';
import { Link, Image } from '../../snippets';
import { AltText, Headline } from '../../snippets/Typography';
import { Schema } from './BlogArticleList.schema';

export function BlogArticleList({ cms }) {
  const blogSettings = useBlog();
  const [articles, setArticles] = useState([]);

  const { desktopColumns, sortBy = 'date' } = cms;

  useEffect(() => {
    if (blogSettings?.articles) {
      let sortedArticles;

      if (sortBy === 'date') {
        // Sort by createdAt date
        sortedArticles = [...blogSettings.articles].sort((a, b) => {
          const dateA = new Date(a.createdAt);
          const dateB = new Date(b.createdAt);
          return dateB - dateA;
        });
      } else {
        // Sort alphabetically by title
        sortedArticles = [...blogSettings.articles].sort((a, b) => {
          return a.title.localeCompare(b.title);
        });
      }

      setArticles(sortedArticles);
    }
  }, [blogSettings?.articles, sortBy]);

  return (
    <section className="my-20">
      <div className="px-contained mx-auto max-w-[var(--content-max-width)]">
        <div
          className={`grid gap-6 gap-y-6 lg:gap-y-10 lg:grid-cols-${desktopColumns}`}
        >
          {articles?.map((article, index) => {
            const articleTime = article.tags.filter((tag) => {
              return tag.includes('articleTime::');
            });
            return (
              <Link
                key={`blog-card-${index}`}
                href={`/articles/${article.handle}`}
              >
                <div key={article.link?.url} className="group">
                  {article.seo?.image && (
                    <div className="relative h-[265px] w-full lg:h-[275px]">
                      <Image
                        src={article.seo.image}
                        alt={article.title}
                        loading="lazy"
                        sizes="400px"
                        quality={100}
                        fill
                      />
                    </div>
                  )}

                  <div className="mb-2 mt-4 uppercase">
                    <AltText className="!lg:text-[14px] uppercase tracking-[0.04em]">
                      {article.category}{' '}
                      <span className="text-shadowGray">
                        {articleTime && articleTime.length
                          ? `• ${articleTime[0].split('::')[1]} MIN READ`
                          : null}
                      </span>
                    </AltText>
                  </div>

                  <Headline
                    variant="h5"
                    className="tracking-[-.01em] lg:text-[22px]"
                  >
                    {article.title}
                  </Headline>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
}

BlogArticleList.displayName = 'BlogArticleList';
BlogArticleList.Schema = Schema;
