import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { Carousel, Image } from '../../snippets';
import useMediaQuery from '../../utils/useMediaQuery';
/**
 * @typedef Image
 * @type {object}
 * @property {string} src - The image source.
 * @property {string} alt - The image alt text.
 */

/**
 * @typedef OnChange
 * @type {function}
 * @param {number} index - The index of the current slide.
 */

/**
 * Step carousel/slider made for product detail page.
 * @param {object} props - The props that were defined by the caller of this component.
 * @param {Array.<Image>} props.images - The content that will came from PACK.
 * @param {OnChange} props.onChange - The onChange function.
 * @param {number} props.currentSlide - The current slide.
 *
 * @component
 * @example
 * const images = cms.images;
 * return (
 *  <ProductStepsImageCarousel
 *    images={images}
 *    onChange={console.log}
 *  />
 * )
 */
function ProductStepsImageCarousel({ images, onChange, currentSlide }) {
  const slideArray =
    images.map((slide) => {
      return {
        type: slide.type,
        url: typeof slide.item === 'string' ? slide.item : slide.item.src,
      };
    }) ?? [];

  const isMobile = useMediaQuery('(max-width: 767px)');

  const [showArrows, setShowArrows] = useState(false);
  useEffect(() => {
    setShowArrows(!isMobile);
  }, [isMobile]);

  return (
    <div className="-mx-4 md:mx-0">
      <Carousel
        showArrows={showArrows}
        emulateTouch
        showIndicators={false}
        onChange={onChange}
        selectedItem={currentSlide}
      >
        {slideArray.map((item, index) => (
          <div
            key={index}
            className="relative mx-auto h-[240px] w-full bg-[#EDF3F9] dark:bg-[#202635] md:h-[480px] md:max-w-[var(--content-max-width)] lg:h-[630px]"
          >
            {item.url &&
              (item.type === 'image' ? (
                <Image
                  src={item.url}
                  alt="Product step image"
                  fill
                  style={{ objectFit: 'contain' }}
                />
              ) : (
                <video
                  autoPlay
                  muted
                  loop
                  playsInline
                  className="h-full w-full object-contain"
                >
                  <source src={item.url} type="video/mp4" />
                </video>
              ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
}

ProductStepsImageCarousel.propTypes = {
  /**
   * Images array
   */
  images: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      item: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        }),
      ]),
    })
  ),
};

ProductStepsImageCarousel.defaultProps = {
  images: [],
};

export default ProductStepsImageCarousel;
