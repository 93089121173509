import Button from '../../snippets/Button/Button';
import { Body, Eyebrow, Headline } from '../../snippets/Typography';
import Schema from './PromoBanner.schema';
import { Link, Image } from '../../snippets';

export default function PromoBanner({ cms }) {
  const {
    eyebrowText,
    heading,
    image,
    promoEyebrowText,
    promoHeading,
    promoDescription,
    ctas,
  } = cms;

  return (
    <section className="px-contained mx-auto my-20 flex max-w-[var(--content-max-width)] flex-col gap-10">
      <div className="flex flex-col gap-4 lg:text-center">
        <Eyebrow>{eyebrowText}</Eyebrow>
        <Headline variant="h3">{heading}</Headline>
      </div>

      <div className="flex flex-col lg:flex-row">
        <div className="flex-1 shrink-0">
          <Image
            src={image.src}
            alt={image.alt}
            className="w-full object-cover"
            width={image.width}
            height={image.height}
          />
        </div>
        <div className="flex flex-1 shrink-0 items-center justify-center bg-gradient-to-r from-[#C1EFFA] to-[#8DCEF9] px-6 py-11">
          <div className="flex flex-col gap-4 lg:max-w-[452px]">
            <Eyebrow>{promoEyebrowText}</Eyebrow>
            <Headline variant="h3">{promoHeading}</Headline>
            <Body variant="medium">{promoDescription}</Body>
            <div className="flex items-center gap-5">
              {ctas.map((cta, index) => {
                if ('link' in cta) {
                  if (cta?.isRegularLink) {
                    return (
                      <Link
                        className="underline underline-offset-2"
                        href={cta.link.url}
                      >
                        {cta.link.text}
                      </Link>
                    );
                  }
                  return (
                    <Button
                      key={index}
                      href={cta.link.url}
                      variant={cta.variant}
                      theme={cta.theme}
                    >
                      {cta.link.text}
                    </Button>
                  );
                }

                return null;
              })}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

PromoBanner.Schema = Schema;
